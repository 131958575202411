// src/pages/Home.tsx
import React, { useEffect, useState, useRef } from 'react';
import { fetchCartData } from '../api/cartMonitor';
import { Cart } from '../models/cart';
import CartGrid from '../components/CartGrid';
import { useNavigate } from 'react-router-dom';
import '../styles/Home.css';
import ezyCartLogo from '../assets/ezycart_logo.svg';
import sectionTitleIcon from '../assets/section_title_icon.svg'; // Add Section title icon
import sectionTitleIdleIcon from '../assets/section_title_idle_icon.svg'; // Add Section title icon
import sectionTitleAbandonedIcon from '../assets/section_title_abandoned_icon.svg'; // Add Section title icon

import { decryptData } from '../utils/encryption';
import exitSound from '../assets/exit_zone_without_payment.wav';

const Home = () => {
  const [cartData, setCartData] = useState<Cart[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isPlayingRef = useRef(false);

  useEffect(() => {
    const loadCartData = async () => {
      try {
        const outletData = localStorage.getItem('selectedOutlet');
        if (outletData) {
          const decryptedOutletData = decryptData(outletData);
          const data = await fetchCartData(decryptedOutletData.merchantId, decryptedOutletData.outletId);
          setCartData(data.data);
          setLoading(false);

          const exitZoneCart = data.data.some((cart: Cart) => cart.CartStatus === 'ACTIVE_SUSPICIOUS_EXIT');
          if (exitZoneCart && !isPlayingRef.current) {
            console.log('Playing sound');
            playSound();
          } else if (!exitZoneCart && isPlayingRef.current && audioRef.current) {
            // Stop the sound if there's no exit zone cart anymore
            console.log('Stopping sound');
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            isPlayingRef.current = false;
          } else {
            console.log('No action needed');
          }
        } else {
          console.error('No merchant Info found..');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching cart data', error);
        setLoading(false);
      }
    };

    loadCartData();

    const intervalId = setInterval(() => {
      loadCartData();
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // 1. Request wake lock
    const requestWakeLock = async () => {
      try {
        if ('wakeLock' in navigator) {
          const wakeLock = await (navigator as any).wakeLock.request('screen');
          console.log('Wake Lock is active');
          
          // Re-request wake lock if page becomes visible again
          document.addEventListener('visibilitychange', async () => {
            if (document.visibilityState === 'visible') {
              await (navigator as any).wakeLock.request('screen');
              console.log('Wake Lock re-activated');
            }
          });
        } else {
          console.log('Wake Lock API not supported');
        }
      } catch (err) {
        console.error('Wake Lock error:', err);
      }
    };

    // 2. Keep the page active by making minimal movements
    const keepAlive = () => {
      const timestamp = new Date().toLocaleTimeString();
      console.log(`Keeping page alive: ${timestamp}`);
      window.scrollBy(0, 1);
      setTimeout(() => window.scrollBy(0, -1), 500);
    };

    // Initialize wake lock
    requestWakeLock();

    // Set up keep-alive interval
    const keepAliveInterval = setInterval(keepAlive, 30000); // Every 30 seconds

    return () => {
      clearInterval(keepAliveInterval);
    };
  }, []);

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => {
        console.error('Audio play failed:', err);
      });
      isPlayingRef.current = true;

      audioRef.current.onended = () => {
        isPlayingRef.current = false;
      };
    }
  };

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      localStorage.removeItem('userData');
      localStorage.removeItem('selectedOutlet');
      navigate('/');
    }
  };

  const activeCarts = cartData.filter(cart => cart.CartStatus.includes('ACTIVE') || cart.CartStatus.includes('PAYMENT'));
  const idleCarts = cartData.filter(cart => cart.CartStatus.includes('IDLE'));
  const abandonedCarts = cartData.filter(cart => cart.CartStatus.includes('ABANDON'));

  return (
    <div className="home-container">
      <div className="navbar">
        <img src={ezyCartLogo} alt="ezyCart Logo" className="logo" />
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>

      <audio ref={audioRef} src={exitSound} preload="auto" />

      {loading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          {/* Active Carts Section */}
          {activeCarts.length > 0 && (
            <div className="cart-section">
              <h2 className="section-title">
                <img src={sectionTitleIcon} alt="Icon" className="section-title-icon" />
                  Active Carts ({activeCarts.length})
              </h2>
              <div className="cart-grid-container">
                {activeCarts.map((cart, index) => (
                  <CartGrid key={index} cart={cart} index={index + 1} section="active" />
                ))}
              </div>
            </div>
          )}

          {/* Idle Carts Section */}
          {idleCarts.length > 0 && (
            <div className="cart-section">
              <h2 className="section-title">
                <img src={sectionTitleIdleIcon} alt="Icon" className="section-title-icon" />
                  Idle Carts ({idleCarts.length})
              </h2>
              <div className="cart-grid-container">
                {idleCarts.map((cart, index) => (
                  <CartGrid key={index} cart={cart} index={index + 1} section="idle" />
                ))}
              </div>
            </div>
          )}

          {/* Abandoned Carts Section */}
          {abandonedCarts.length > 0 && (
            <div className="cart-section">
              <h2 className="section-title">
                <img src={sectionTitleAbandonedIcon} alt="Icon" className="section-title-icon" />
                  Abandoned Carts ({abandonedCarts.length})
              </h2>
              <div className="cart-grid-container">
                {abandonedCarts.map((cart, index) => (
                  <CartGrid key={index} cart={cart} index={index + 1} section="abandoned" />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Home;